import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { direcionaParaApp } from "@/services/utils";
import "./index.scss";

export default function PaginaRedireciona() {
  const { chave } = useParams();
  const [carregar, setCarregar] = useState(false);

  useEffect(() => {
    const carregaIaSimula = async () => {
      const metaData = {};
      metaData.utmSource = "chat_ia_simulacao";
      metaData.utmMedium = "chat_ia_simulacao";
      metaData.utmCampaign = "chat_ia_simulacao";
      metaData.utmId = "";
      metaData.utmTerm = "";
      const metadataRegister = {
        ...metaData,
        isMobile: isMobile,
        userAction: "OPEN_IA_SIMULATION",
        url: window.location.href,
      };
      await registerMetadata(metadataRegister);
      setTimeout(() => {
        direcionaParaApp(`/app/simulacao`);
      }, 2000);
    };
    if (carregar && chave) {
      setCarregar(false);
      if (chave === "ia-simula") {
        carregaIaSimula();
      } else {
        setTimeout(() => {
          direcionaParaApp(`/app/simulacao`);
        }, 2000);
      }
    }
  }, [carregar, chave]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  return (
    <Container
      id="carregamento-simulacao"
      component="section"
      className="sessao-site lp-carregamento">
      <Helmet>
        <title>Carregando Simulação</title>
        <link rel="canonical" href="https://quitaboletos.com.br" />
        <meta
          name="description"
          content="Simule o parcelamento do seu PIX em até 12x no cartão de crédito."
        />
      </Helmet>

      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Grid sx={{ px: { xs: 2 }, width: { xs: "100%", md: "40%" } }}>
            <Typography className="title">
              Você está sendo direcionado para <strong>simulação:</strong>
            </Typography>
          </Grid>
          <div className="loader">
            <div className="item-loader"></div>
            <div className="item-loader"></div>
            <div className="item-loader"></div>
            <div className="item-loader"></div>
            <div className="item-loader"></div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
